<template>
  <div class="print__attribute__header">
    <b-row>
      <b-col md="4" lg="4" class="col-print-4">
        <div class="header__attribute__label">{{ $t('report.position') }}</div>
        <div class="header__attribute__value">{{ campaign.job_title_short }}</div>
      </b-col>

      <b-col md="4" lg="4" class="col-print-4">
        <div class="header__attribute__label">{{ $t('report.job_id') }}</div>
        <div class="header__attribute__value">{{ campaign.internal_job_id_1 }}</div>
      </b-col>

      <b-col md="4" lg="4" class="col-print-4">
        <div class="header__attribute__label">{{pageTitle}} {{ $t('report.campaign_id') }}</div>
        <div class="header__attribute__value">{{ campaign.campaign_id }}</div>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import franchise from '../../../conf/franchise';
export default {
  props: {
    campaign: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    pageTitle() {
      return franchise.pageTitle;
    }
  }
};
</script>
