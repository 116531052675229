<template>
  <div class="report__chapter">
    <b-row>
      <b-col>
        <h3 class="report__chapter__title">
          <span :class="icon"></span>
          <span>{{ report_title }} {{ candidate_score }} ({{ $t('report.high_score')}} {{ highScore }})</span>
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
            class="report__ems__table"
            ref="table"
            :fields="fields"
            :items="items"
            :borderless="true"
            responsive="sm"
            fixed="fixed">

          <template v-slot:cell(name)="{item}">
            <span class="text-truncate">{{ item.name }}</span>
          </template>

          <template #head(progress)>
            <div class="table__head">
              <!--<span>{{ $t('report.relative_weaker') }}</span>
              <span>{{ $t('report.relative_stronger') }}</span>-->
            </div>
          </template>

          <template v-slot:cell(progress)="{item}">
            <div class="d-flex double-progress w-100">
              <div class="double-progress-line"></div>

              <b-progress class="progress-left"
                          :value="item.progress <= 0 ? Math.abs(item.progress) : 0"
                          :max="max"
                          :variant="item.progress >= 0 ? 'success' : 'info2'"></b-progress>

              <b-progress class="progress-right"
                          :value="item.progress >= 0 ? item.progress : 0" :max="max"
                          :variant="item.progress >= 0 ? 'success' : 'info2'"></b-progress>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: []
    },
    fields: {
      type: Array,
      required: true,
      default: []
    },
    icon: {
      type: String,
      required: true,
      default: ''
    },
    candidate_score: {
      type: Number,
      required: true,
      default: 0
    },
    highScore: {
      type: Number,
      default: 0
    },
    report_title: {
      type: String,
      required: true,
      default: ''
    },
  },
  data() {
    return {
      max: 100
    };
  },
};
</script>
