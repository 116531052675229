import { mapGetters } from "vuex";
import {
  CAMPAIGN_TYPES,
  JOB_COMPLEXITY,
  CURRENCIES,
  JOB_RANGE_TYPES,
  JOB_ARRANGEMENT_TYPES,
  JOB_ARRANGEMENT_PERIODS } from "./constants";
const CampaignHelpersMixin = {
  computed: {
    ...mapGetters("global", ["currentLanguage", "campaignAttributes", "titles"]),
    clientTitles() {
      return this.titles.map(title => ({
        text: title.text,
        value: title.value,
      }))
    },

    jobLevels() {
      return window.job_levels.map((jobLevel) => ({
        text: jobLevel[`label_${this.currentLanguage.toLowerCase()}`],
        value: "" + jobLevel.value
      }));
    },

    jobCategories() {
      return window.job_categories.map((jobCategory) => ({
        text: jobCategory[`label_${this.currentLanguage.toLowerCase()}`],
        value: jobCategory.value
      }));
    },

    clientContactRoles() {
      return window.job_roles.map((jobRole) => ({
        text: jobRole[`label_${this.currentLanguage.toLowerCase()}`],
        value: jobRole.value
      }));
    },

    valuesAttributes() {
      return Object.values(this.campaignAttributes.values);
    },

    competenciesAttributes() {
      return Object.values(this.campaignAttributes.competencies);
    },

    traitsAttributes() {
      return Object.values(this.campaignAttributes.traits);
    },

    campaignTypes() {
      return [
        { text: this.$t('create_campaign.job_title'), value: CAMPAIGN_TYPES.JOB },
        { text: this.$t('create_campaign.team_title'), value: CAMPAIGN_TYPES.TEAM },
        { text: this.$t('create_campaign.selftest_title'), value: CAMPAIGN_TYPES.SELFTEST },
      ]
    },

    aplicantPortalCampaignTypes () {
      return [
        { text: this.$t('create_campaign.job_title'), value: CAMPAIGN_TYPES.JOB },
        { text: this.$t('create_campaign.selftest_title'), value: CAMPAIGN_TYPES.SELFTEST },
        { text: this.$t('create_campaign.no_assessment'), value: CAMPAIGN_TYPES.NO_ASSASMENT },
      ]
    },

    jobComplexity() {
      return [
        { text: this.$t(`job_complexity.${JOB_COMPLEXITY.LOW}`), value: JOB_COMPLEXITY.LOW },
        { text: this.$t(`job_complexity.${JOB_COMPLEXITY.MEDIUM}`), value: JOB_COMPLEXITY.MEDIUM },
        { text: this.$t(`job_complexity.${JOB_COMPLEXITY.HIGH}`), value: JOB_COMPLEXITY.HIGH },
        { text: this.$t(`job_complexity.${JOB_COMPLEXITY.VERY_HIGH}`), value: JOB_COMPLEXITY.VERY_HIGH },
      ]
    },

    currencies() {
      return CURRENCIES;
    },

    jobRangeTypes() {
      return [
        { text: this.$t(`job_range_type.${JOB_RANGE_TYPES.NO_RANGE}`), value: JOB_RANGE_TYPES.NO_RANGE },
        { text: this.$t(`job_range_type.${JOB_RANGE_TYPES.HOUR}`), value: JOB_RANGE_TYPES.HOUR },
        { text: this.$t(`job_range_type.${JOB_RANGE_TYPES.DAY}`), value: JOB_RANGE_TYPES.DAY },
        { text: this.$t(`job_range_type.${JOB_RANGE_TYPES.WEEK}`), value: JOB_RANGE_TYPES.WEEK },
        { text: this.$t(`job_range_type.${JOB_RANGE_TYPES.MONTH}`), value: JOB_RANGE_TYPES.MONTH },
        { text: this.$t(`job_range_type.${JOB_RANGE_TYPES.YEAR}`), value: JOB_RANGE_TYPES.YEAR },
        { text: this.$t(`job_range_type.${JOB_RANGE_TYPES.PROJECT}`), value: JOB_RANGE_TYPES.PROJECT },
      ]
    },

    jobArrangementTypes() {
      const types = [];
      Object.keys(JOB_ARRANGEMENT_TYPES).forEach(key => {
        types.push({
          value: key.toLowerCase(),
          text: this.$t(`job_arrangement_types.${JOB_ARRANGEMENT_TYPES[key]}`)
        });
      });
      return types;
    },

    jobArrangementPeriods() {
      return JOB_ARRANGEMENT_PERIODS;
    },

    documentType() {
      return window.seeker_document_types.map((docType) => ({
        text: docType[`label_${this.currentLanguage.toLowerCase()}`],
        value: docType.value,
        slug: docType.slug
      }));
    },
  },
};

export default CampaignHelpersMixin;
