<template>
  <ValidationObserver ref="form" tag="div">
    <div class="step__item__content">
      <div class="step__item__index">{{ $t('match_candidate.step_3') }}</div>
      <div class="step__item__title">
        {{ $t('applicant_portal.define_personality') }}
        <a
          :title="$t('match_references.candidate_info')"
          href="https://www.examiz.com/personality_attributes/"
          target="_blank"
        >
          <i class="mdi mdi-information-outline icon_info"></i>
        </a>
      </div>
      <div class="step__item__description">
        {{ $t('applicant_portal.which_personality') }}
      </div>
      <b-button
        v-show="!showEditBtn"
        variant="primary"
        size="md"
        v-b-toggle.step-3
        class="step__item__trigger"
        :disabled="isDisabled"
        >{{ setupBtnText }}</b-button>
      <b-button
        v-show="showEditBtn"
        variant="outline-primary"
        size="md"
        v-b-toggle.step-3
        class="step__item__trigger"
        >{{ editBtnText }}</b-button>

      <b-collapse ref="step-3" id="step-3" class="steptoggle__content mb-0">
        <b-col lg="12" class="p-0">
          <div class="values-column">
            <div class="form__title"></div>
            <b-form-checkbox v-if="!isActivated" v-model="form.applicant_portal.bulk_hiring" @change="onBulkHiringCheck"> {{ $t('applicant_portal.bulk_hiring') }} </b-form-checkbox>
            <p v-if="form.bulk_hiring" class="pt-3">
              {{ $t('applicant_portal.bulk_hiring_info') }}
            </p>
          </div>

           <!-- // Bulk hiring  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
          <b-form v-show="form.applicant_portal.bulk_hiring" class="form__section">
            <div class="competencies-column row">
              <div class="form__title col-lg-3 col-sm-12">
                <div class="sticky-top pt-4">{{ $t('applicant_portal.profiles') }}</div>
              </div>

              <div class="col-lg-9 col-sm-12 pt-lg-4">
                <ValidationProvider
                  :name="$t('create_campaign.competencies')"
                  vid="competencies"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <multiselect
                    label="title"
                    track-by="id"
                    selectLabel=""
                    deselectLabel=""
                    v-model="bulkHiringProfile"
                    :preselectFirst="true"
                    :placeholder="$t('general.select')"
                    :options="bulkHiringTypes"
                    @select="onProfileSelect"
                  ></multiselect>

                  <div class="error-message">{{ errors[0] }}</div>
                </ValidationProvider>

              <p v-html="bulkHiringProfile.description" class="pt-3"></p>
              </div>
            </div>
          </b-form>

          <!-- // standard attributes ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
          <div v-show="!form.applicant_portal.bulk_hiring" class="values-column form__section row">
            <div class="form__title col-lg-3 col-sm-12 text-center">
              <div class="sticky-top pt-4">
                <div class="mb-2">
                  {{ $t('create_campaign.standard_attributes') }}
                </div>
                <img
                  src="../../../images/campaign_icons/problem_solving.svg"
                  alt="Problem solving"
                  width="60"
                />
              </div>
            </div>
            <!-- <div class="form__title">
              {{ $t('create_campaign.standard_attributes') }}
            </div> -->
            <div class="col-lg-9 col-sm-12 pt-lg-4">
              <div
                class="progress-item"
                v-for="(item, index) in permanentAttributes"
                :key="index"
              >
                <span class="progress-item-badge">{{ item.name }}</span>
              </div>
              <p class="pt-3">
                {{ $t('create_campaign.standard_attributes_info') }}
              </p>
            </div>
          </div>
          <hr v-show="!form.applicant_portal.bulk_hiring" />

          <!-- // competencies ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
          <b-form class="form__section">
            <div v-show="!form.applicant_portal.bulk_hiring" class="competencies-column row">
              <div class="form__title col-lg-3 col-sm-12 text-center">
                <div class="sticky-top pt-4">
                  <div class="mb-2">
                    {{ $t('create_campaign.competencies') }}
                  </div>
                  <img
                    src="../../../images/campaign_icons/user_settings.svg"
                    alt="Competencies"
                    width="60"
                  />
                </div>
              </div>
              <!-- <div class="form__title">
                {{ $t('create_campaign.competencies') }}
              </div> -->

              <div class="col-lg-9 col-sm-12 pt-lg-4">
                <ValidationProvider
                  :name="$t('create_campaign.competencies')"
                  vid="competencies"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <app-multiselect
                    v-model="form.personality_attrs.competencies"
                    :disabled="isActivated"
                    :options="competenciesAttributes"
                    :label="`name_${currentLanguage}`"
                    :placeholder="$t('create_campaign.competencies_placeholder')"
                    :max="5"
                    trackBy="attribute"
                  >
                  </app-multiselect>
                  <div class="error-message">{{ errors[0] }}</div>
                </ValidationProvider>

                <div class="mt-5">
                  <div
                    v-for="(item, index) in form.personality_attrs.competencies"
                    :key="index"
                  >
                    <div class="skill-label">
                      {{ item[`name_${currentLanguage}`] }}
                    </div>
                    <vue-slider
                      v-model="item.value"
                      dotSize="12"
                      :height="3"
                      :lazy="true"
                      :interval="1"
                      :marks="marks"
                      :tooltip="'always'"
                      :tooltip-placement="'top'"
                    >
                    </vue-slider>
                  </div>
                </div>

              </div>
            </div>
            <hr v-show="!form.applicant_portal.bulk_hiring" />

            <!-- // traits ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
            <div v-show="!form.applicant_portal.bulk_hiring" class="traits-column form__section row">
              <div class="form__title col-lg-3 col-sm-12 text-center">
                <div class="sticky-top pt-4">
                  <div class="mb-2">
                    {{ $t('create_campaign.traits') }}
                  </div>
                  <img
                    src="../../../images/campaign_icons/creative_idea.svg"
                    alt="Traits"
                    width="60"
                  />
                </div>
              </div>

              <!-- <div class="form__title">{{ $t('create_campaign.traits') }}</div> -->

              <div class="col-lg-9 col-sm-12 pt-lg-4">
                <ValidationProvider
                  :name="$t('create_campaign.traits')"
                  vid="traits"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <app-multiselect
                    v-model="form.personality_attrs.traits"
                    :disabled="isActivated"
                    :options="traitsAttributes"
                    :placeholder="$t('create_campaign.competencies_placeholder')"
                    :max="5"
                    :label="`name_${currentLanguage}`"
                    trackBy="attribute"
                  >
                  </app-multiselect>
                  <div class="error-message">{{ errors[0] }}</div>
                </ValidationProvider>

                <div class="mt-5">
                  <div
                    v-for="(item, index) in form.personality_attrs.traits"
                    :key="index"
                  >
                    <div class="skill-label">
                      {{ item[`name_${currentLanguage}`] }}
                    </div>
                    <vue-slider
                      v-model="item.value"
                      dotSize="12"
                      :height="3"
                      :lazy="true"
                      :interval="1"
                      :marks="marks"
                      :tooltip="'always'"
                      :tooltip-placement="'top'"
                    >
                    </vue-slider>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 p-0 d-flex justify-content-end">
              <b-button class="wide" variant="primary" size="md" @click="validateForm()">{{
                $t('general.save_changes')
              }}</b-button>
            </div>
          </b-form>
        </b-col>
      </b-collapse>
    </div>
  </ValidationObserver>
</template>

<script>
import AppMultiselect from '@/components/CustomMultiselect';
import CampaignHelpersMixin from '@/common/CampaignHelpersMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'DefinePersonalityProfile',
  props: {
    isActivated: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [CampaignHelpersMixin],
  components: {
    AppMultiselect,
  },
  data() {
    return {
      form: {
        personality_attrs: {},
        applicant_portal: {
          bulk_hiring: false
        }
      },
      marks: [0, 100],
      isValid: false,
      bulkHiringProfile: {}
    };
  },
  computed: {
    ...mapGetters('global', ['permanentAttributes', 'bulkHiringTypes', 'bulkHiringProfiles']),
    ...mapGetters('campaign', ['current']),
    showEditBtn() {
      return this.isActivated || this.isValid;
    },
    editBtnText() {
      return this.$t('dashboard.edit');
    },
    setupBtnText() {
      return this.$t('general.setup');
    }
  },
   created() {
    if (this.current.id || (this.campaign && this.campaign.info && this.campaign.info.name)) {
      this.form.personality_attrs = this.current.personality_attrs;
      this.form.applicant_portal.bulk_hiring = this.current.ap_data ? this.current.ap_data.bulk_hiring : false;
      this.bulkHiringProfile = this.current.ap_data && this.current.ap_data.bulk_hiring_profile ? this.current.ap_data.bulk_hiring_profile : {};
      this.$emit('currentForm', this.form);
    }
  },
  methods: {
    setIsValid(value) {
      this.isValid = value;
    },
    onBulkHiringCheck() {
      this.bulkHiringProfile = {};
      this.form.personality_attrs.competencies = [];
      this.form.personality_attrs.traits = [];
    },
    onProfileSelect(profile) {
      const attributes = this.bulkHiringProfiles[profile.id];
      attributes.forEach((attr) => {
        const competencAttribute = this.competenciesAttributes.find(item => item.attribute === attr.attribute_id)
        if (competencAttribute) {
          // Clone campaign hack because onBulkHiringCheck is not triggered
          if (!this.form.personality_attrs.competencies) {
            this.form.personality_attrs.competencies = []
          }

          this.form.personality_attrs.competencies.push(
            Object.assign({}, competencAttribute, { value: attr.value})
          )
          return;
        }

        const traitsAttribute = this.traitsAttributes.find(item => item.attribute === attr.attribute_id)
        if (traitsAttribute) {
          // Clone campaign hack because onBulkHiringCheck is not triggered
          if (this.form.personality_attrs.traits) {
            this.form.personality_attrs.traits = []
          }
           this.form.personality_attrs.traits.push(
            Object.assign({}, traitsAttribute, { value: attr.value})
          )
          return;
        }
      })
    },
    validateForm() {
      this.$refs.form.validate().then((res) => {
        const emitData = {
          id: 2,
          isValidForm: false,
          step: 3,
          disabled: true,
          data: Object.assign({}, this.form),
        };

        this.form.applicant_portal.bulk_hiring_profile = null;
        if (this.bulkHiringProfile.id) {
          this.form.applicant_portal.bulk_hiring_profile = this.bulkHiringProfile.id
        }

        if (res) {
          this.isValid = true;

          emitData.isValidForm = true;
          emitData.disabled = false;

          this.$root.$emit('bv::toggle::collapse', 'step-3');
          this.$root.$emit('bv::toggle::collapse', 'step-4');
        }

        this.$emit('isvalidform', emitData)
      });
    },
  },
};
</script>
