<template>
  <b-dropdown class="companyswitch" v-if="showCompanySwitch" :text="currentCompany.name" variant="link" right>
    <b-dropdown-item @click="swithcCompany(company)" v-for="company in companies" :key="company.id">
      {{ company.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('global', [
      'currentUserCompanyId',
      'currentUserCompanies',
      'isMatch',
    ]),
    companies() {
      return this.currentUserCompanies.filter(
        (c) => c.id !== this.currentUserCompanyId
      );
    },
    currentCompany() {
      return (
        this.currentUserCompanies.find(
          (c) => c.id === this.currentUserCompanyId
        ) || null
      );
    },
    showCompanySwitch() {
      return this.currentCompany && this.currentUserCompanies.length > 1 && this.isMatch
    }
  },
  methods: {
    swithcCompany(company) {
      localStorage.setItem('company-id', company.id);
      location.reload();
    },
  },
};
</script>
