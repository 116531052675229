<template>
  <div class="report" :id="pageId">

    <div class="print__container">

      <b-button
          @click="printReport()"
          v-b-tooltip.hover
          variant="primary"
          :title="$t('report.print_report')"
          class="print__button">
        <span class="mdi mdi-printer"></span>
      </b-button>

    </div>

    <div class="report__content">

      <!-- // page: campaign cover ///////////////////////////////////////// -->

      <section class="page_campaign_cover">

        <print-header :client-name="campaignClientName"></print-header>
        <coverpage-campaign :campaign="campaign"
                            :attributes="attributes"
                            :candidate-count="candidateCount"
                            :candidate-references="candidateCountReferences"
                            :campaign-dates="campaignDetailsDates"></coverpage-campaign>
        <ReportFooter/>
      </section>

      <!-- // page: campaign summary /////////////////////////////////////// -->

      <section class="page-break-before-always page-break-inside-avoid page_campaign_summary">
        <print-header :client-name="campaignClientName"></print-header>
        <report-page-header :campaign="campaign"></report-page-header>
        <b-row>
          <b-col>

            <!-- // toggle ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <div class="toggle">

              <h2 class="report__section__title toggle__trigger">
                <span class="toggle__trigger__text">{{ $t("report.best_match_score") }}</span>
              </h2>

              <b-collapse ref="collapsibleCandidate" id="best-match-score" class="toggle__content" visible>

                <!-- // best match score ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                <report-best-match
                    :report="report"
                    :items="bestMatches"
                    :campaign="campaign"
                    :attributes="attributes"
                    :filteredAttr="filteredAttr"
                    @change-candidates="changeCandidates"
                    :filteredCandidates="filteredCandidates"></report-best-match>
              </b-collapse>
            </div>

            <!-- // toggle ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <div class="toggle" v-if="isSelectedCandidate">

              <h2 class="report__section__title toggle__trigger">
                <span class="toggle__trigger__text">{{ $t("report.potential_analytics") }}</span>
              </h2>

              <b-collapse ref="collapsiblePotential" id="potential-analytics" class="toggle__content" visible>

                <!-- // potential analytics ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                <report-potential-analytics 
                :report="report" 
                :filteredCandidates="filteredCandidates"
                :hideLegend="isLegendHidden"
                :isPrintedReport="true"
                ></report-potential-analytics>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </section>

      <!-- // page: candidate cover //////////////////////////////////////// -->

      <div v-for="(candidate, index) in candidates" :key="candidate.candidate_id">

        <section class="page-break-before-always page-break-inside-avoid page_candidate_cover">
          <div class="candidate__rank__index">{{ index + 1 }}</div>
          <print-header :client-name="campaignClientName"></print-header>
          <coverpage-candidate :campaign="campaign"
                              :full-name="candidate.candidate_full_name"
                              :is-candidate-survey-valid="isCandidateSurveyValid(index)"
                              :candidate-match-score="getCandidateBestMatchScore(index)"></coverpage-candidate>

        </section>

        <!-- // page: candidate detail ///////////////////////////////////// -->

        <section class="page-break-before-always page-break-inside-avoid page_candidate_detail">

          <print-header :client-name="campaignClientName"></print-header>

          <!-- // candidate header ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->

          <report-page-header :campaign="campaign"></report-page-header>

          <b-row v-if="candidate.best_match_score !== 0">
            <b-col>

              <!-- // toggle ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

              <div class="toggle">

                <h2 class="report__section__title toggle__trigger">
                  <span class="toggle__trigger__text">
                    <span class="report__candidate__index">{{ index + 1 }}.</span>
                    {{ candidate.candidate_full_name }}
                  </span>
                </h2>

                <!-- // toggle content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

                <b-collapse ref="collapsible" :id="`uid-${candidate.candidate_id}`" class="toggle__content" visible>

                  <report-candidate
                      :candidate="candidate"
                      :campaign="campaign"></report-candidate>

                </b-collapse>
              </div>
            </b-col>
          </b-row>
        </section>

        <!-- // page: interview questions ////////////////////////////////// -->

        <section class="page-break-before-always page-break-inside-avoid page_interview_questions">
          <print-header :client-name="campaignClientName"></print-header>
          <report-page-header :campaign="campaign"></report-page-header>
          <print-interview-questions
              :campaign="campaign"
              :report="report"
              :candidate="candidate"
              :interviewQuestions="questions"></print-interview-questions>
        </section>
      </div>

      <!-- // page: personality types //////////////////////////////////// -->

      <section class="page-break-before-always page-break-inside-avoid page_personality_types">
        <print-header :client-name="campaignClientName"></print-header>
        <print-personality-types :report="report"></print-personality-types>
      </section>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { get, isArray } from "lodash";
import PrintHeader from "../Report/Print/PrintHeader";
import Header from "./Header";
import Footer from '@/components/FooterPrint';
import ReportPageHeader from "./Candidate/ReportPageHeader";
import CoverpageCampaign from "./CoverpageCampaign";
import InterviewQuestions from "./Print/InterviewQuestions";
import PersonalityTypesTitle from "./Print/PersonalityTypesTitle";
import PersonalityTypes from "./Print/PersonalityTypes";
import CampaignOverview from "./CampaignOverview";
import BestMatch from "./BestMatch";
import PotentialAnalytics from "./PotentialAnalytics";
import Candidate from "./Candidate/Index";
import CoverpageCandidate from "./Print/CoverpageCandidate";
import CandidateDetailsPage from "./Print/CandidateDetailsPage";
import { CAMPAIGN_TYPES } from '../../common/constants';

export default {
  props: {
    pageId: {
      type: String,
      default: "printreport__fullview",
    },
  },
  components: {
    PrintHeader: PrintHeader,
    ReportHeader: Header,
    ReportFooter: Footer,
    ReportPageHeader: ReportPageHeader,
    CoverpageCampaign: CoverpageCampaign,
    PrintInterviewQuestions: InterviewQuestions,
    PrintPersonalityTypesTitle: PersonalityTypesTitle,
    PrintPersonalityTypes: PersonalityTypes,
    ReportCampaignOverview: CampaignOverview,
    ReportBestMatch: BestMatch,
    ReportPotentialAnalytics: PotentialAnalytics,
    ReportCandidate: Candidate,
    CoverpageCandidate: CoverpageCandidate,
    PrintCandidateDetailsPage: CandidateDetailsPage,
  },
  data() {
    return {
      candidates: [],
      bestMatches: [],
      filteredCandidates: [],
      filteredAttr: [],
      filteredQuestions: [],
      breadcrumbs: [
        {
          text: "Dashboard",
          to: { name: "Home" },
        },
        {
          text: "Campaign ID 2389329839",
          active: true,
        },
        {
          text: "Report",
          active: true,
        },
      ],
      exapandCollapse: false,
      isAnonymous: false,
      isLegendHidden: false
    };
  },
  computed: {
    ...mapGetters("report", ["report", "reportCampaign"]),
    ...mapGetters("campaign", ["current"]),
    campaign() {
      return this.reportCampaign;
    },
    attributes() {
      const result = [];
      const allAttributes = [
        ...attributes.competencies,
        ...attributes.traits,
        ...attributes.values,
      ].filter(
        (a) => a.language === (localStorage.getItem("language") || "en")
      );

      this.attributesListDefault.forEach((item) => {
        const matchAttribute = allAttributes.find(
          (a) => a.value === item.attribute || a.attribute === item.attribute
        );
        if (matchAttribute) result.push(Object.assign(matchAttribute, item));
      });
      return result;
    },
    questions() {
      return isArray(this.filteredQuestions) && this.filteredQuestions.length
        ? this.filteredQuestions
        : this.report.interview_questions;
    },
    attributesListDefault() {
      return [
        ...get(this.reportCampaign, "personality_attrs.competencies", []),
        ...get(this.reportCampaign, "personality_attrs.traits", []),
        ...get(this.reportCampaign, "personality_attrs.values", []),
      ];
    },
    isSelectedCandidate() {
      return this.filteredCandidates.length > 0;
    },
    campaignClientName() {
      return this.campaign.client_name;
    },
    candidateCount() {
      return this.report.candidates_count;
    },
    candidateCountReferences() {
      return this.report.references_count;
    },
    campaignDetailsDates() {
      return {
        start_date: this.$moment(this.campaign.start_date).format("DD MMM YYYY"),
        end_date: this.$moment(this.campaign.end_date).format("DD MMM YYYY")
      }
    }
  },
  created() {
    document.body.classList.add("print__report");
  },
  mounted() {
    this.$store.commit('report/RECALCULATE_REPORT_AVG_VALUES',
      !(this.$route.query.use_candidate_scores_as_benchmark === 'true'));
    this.isLegendHidden = this.$route.query.hide_legend === 'true';

    this.filteredCandidates = this.$route.query.candidates.split(',').map(item => parseInt(item))
    this.filteredCandidates.forEach(item => {
      this.candidates.push(this.report.candidates[item])
      this.bestMatches.push(this.report.best_matches[item])
    })
  },
  destroyed() {
    document.body.classList.remove("print__report");
  },
  methods: {
    changeAttrs(list) {
      this.filteredAttr = this.attributesListDefault.filter(
        (i) => list.indexOf(i.attribute) >= 0
      );
      this.filteredQuestions = this.report.interview_questions.filter(
        (i) => list.indexOf(i.attribute_id) >= 0
      );

      // Force re-rendering child components
      this.$store.commit("report/SET_REPORT", Object.assign({}, this.report));
    },
    changeCandidates(list) {
      this.filteredCandidates = list;
    },
    expandAllCollapses() {
      this.$refs.collapsibleCandidate.show = true;
      this.$refs.collapsiblePotential.show = true;
      this.$refs.collapsible.map((c) => (c.show = true));
    },
    goToReport(id) {
      let route = this.$router.resolve({ name: "Report", params: { id } });
      window.open(route.href, "_blank");
    },
    printReport() {
      let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
      if(isSafari){
        alert(this.$t("general.alert_print_not_work"))
      }
      else{window.print();}
      },
    isCandidateSurveyValid(index) {
      return this.report.candidates[index].is_survey_valid || this.campaign.campaign_type === CAMPAIGN_TYPES.SELFTEST;
    },
    getCandidateBestMatchScore(index) {
      return this.report.candidates[index].best_match_score;
    }
  }
};
</script>
<style lang="scss">
@import "/styles/print";
</style>
