<template>
  <b-table class="dashboard-table" :items="items" :fields="tableFields"
           :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
           @sort-changed="sortingChanged" hover="hover"
           :no-local-sorting="true">

    <template v-slot:cell(select)="data">
      <b-form-checkbox :key="data.item.id"
                       name="checkbox" :checked="isSelected(data.item.id)"
                       @change="$emit('populateStateArray', data.item.id)"></b-form-checkbox>
    </template>

    <template v-slot:cell(internal_id)="data">{{ data.item.info.internal_id }}</template>

    <template v-slot:cell(state)="data">
      <div :class="'table-label table-label__' + data.item.status.toLowerCase()">
        {{ $t(`dashboard.statuses.${data.item.status.toLowerCase()}`) }}
      </div>
    </template>

    <template v-slot:cell(name)="data">
      <router-link :to="`/exit/campaigns/${data.item.id}`">{{ data.item.name }}</router-link>
    </template>

    <template v-slot:cell(admin)="data">
      {{ data.item.admin.first_name }} {{ data.item.admin.last_name }}
    </template>

    <template v-slot:cell(start_date)="data">
      <div v-if="data.item.start_date" class="td-min-width text-left">{{ data.item.start_date | format-date }}</div>
    </template>

    <template v-slot:cell(end_date)="data">
      <div v-if="data.item.end_date" class="td-min-width text-left">{{ data.item.end_date | format-date }}</div>
    </template>

    <template v-slot:cell(progress)="data">
      <div class="progress__bar">
        <div class="progress__value">{{ getProgress(data.item) + '%' }}</div>
        <div class="progress__line"
             v-bind:style="{ width: getProgress(data.item) + '%' }"
             v-bind:class="{ 'progress__line--finished': data.item.progress == 100  }"></div>
        <div class="progress__background"></div>
      </div>
    </template>

    <template v-slot:cell(report)="data">
      <div class="table-icon-wrp text-right">
        <div v-if="data.item.report_present">
          <b-link :to="`/exit/campaigns/${data.item.id}#report`" class="iconlink">
            <span class="mdi mdi-chart-box"></span>
          </b-link>
        </div>
        <div v-else><span class="mdi mdi-chart-box"></span></div>
      </div>
    </template>

    <template v-slot:cell(edit)="data">
      <b-link
        class="iconlink text-right"
        :to="isAnalyticsReport(data.item.campaign_type) ?
        `/exit/campaigns/analytics/${data.item.id}/edit` :
        `/exit/campaigns/${data.item.id}/edit`"
      >
        <span class="mdi mdi-pencil"></span>
      </b-link>
    </template>
  </b-table>
</template>

<script>
import ExitDashboardHelperMixin from "@/common/ExitDashboardHelperMixin";
import Radial from "@/components/RadialProgress";
import {calculateCampaignProgress} from "@/common/utils"
import { mapGetters } from 'vuex';
import { EXIT_CAMPAIGN_TYPES, CAMPAIGN_STATE } from '../../../common/constants';

export default {
  components: {
    Radial
  },
  mixins: [ExitDashboardHelperMixin],
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      require: true,
      default: () => []
    }
  },
  data() {
    return {
      sortBy: "id",
      sortDesc: false,
      user: {
        first_name: '',
        last_name: '',
      },
    };
  },
  computed:{
    ...mapGetters('global', [
      'currentUserFirstName',
      'currentUserLastName',
    ]),
  },
  created() {
    this.user.first_name = this.currentUserFirstName;
    this.user.last_name = this.currentUserLastName;
  },
  methods: {
    getProgress(data){
      return data.status === CAMPAIGN_STATE.OPEN ? 0 : data.progress
    },
    isAnalyticsReport(type){
      return type === EXIT_CAMPAIGN_TYPES.ANALYTICS;
    },
    sortingChanged(ctx) {
      this.$emit("sort", ctx);
    },
    campaignId(item) {
      if (item.id) {
        // return item.id.split('-')[0].toUpperCase();
        return item.id;
      }
      return null;
    },
    isSelected(id) {
      return this.selected.some(itemId => itemId === id);
    },

    calculateCampaignProgress: calculateCampaignProgress
  }
};
</script>
