<template>
  <div class="print__report__header">
    <b-row>
      <b-col md="6" lg="6">
        <img class="print__report__logo" :src="companyLogoUrl || logo" :alt="pageTitle"/>
      </b-col>
      <b-col md="6" lg="6">
        <div class="client__name">{{ clientName }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import franchise from '../../../conf/franchise';

export default {
  computed: {
    ...mapGetters("global", ["companyLogoUrl"]),
    logo() {
      return franchise.logo;
    },
  },
  props: {
    clientName: {
      type: String,
      required: true,
      default: () => ''
    }
  }
};
</script>
