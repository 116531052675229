<template>
  <!-- Two sider progress bar slider -->
  <!--<candidate-self-report-details-table-score
    :report_title="$t('report.performance_score')"
    :talents-attr="talentsAttr"
    :attribute-name="'high_performance'"
    :current-lang="currentLang"
    :score="report.high_performance_score"
    :isBlurred="isBlurred"
    :items="performance_attrs"
    :fields="fields"
    :icon="'mdi mdi-clock-fast'"
    :max="max"></candidate-self-report-details-table-score>-->

    <!-- One sider progress bar slider -->
    <candidate-self-report-details-table
      :report_title="$t('report.performance_score')"
      :talents-attr="talentsAttr"
      :attribute-name="'high_performance'"
      :current-lang="currentLang"
      :score="report.high_performance_score"
      :isBlurred="isBlurred"
      :variant="'blue2'"
      :items="performance_attrs"
      :fields="fields"
      :icon="'mdi mdi-clock-fast'"
      :max="max"></candidate-self-report-details-table>
</template>

<script>
import { orderBy } from "lodash";
//import { calculateTwoSideProgressBarValue } from "@/common/utils";
//import { TALENT_TWO_SIDE_SLIDER_BREAK_POINT } from "@/common/constants";
//import CandidateSelfReportDetailsTableScore from "../CandidateDetailsTables/CandidateSelfReportDetailsTableScore";
import CandidateSelfReportDetailsTableScore from "../CandidateDetailsTables/CandidateSelfReportDetailsTable";
import CandidateSelfReportDetailsTable from "../CandidateDetailsTables/CandidateSelfReportDetailsTable";

export default {
  props: {
    fullName: {
      type: String,
      required: true,
      default: "",
    },
    report: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentLang: {
      type: String,
      required: true,
      default: "en",
    },
    talentsAttr: {
      type: Object,
      required: true,
      default: () => {},
    },
    isBlurred: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    //CandidateSelfReportDetailsTableScore,
    CandidateSelfReportDetailsTable
  },
  data() {
    return {
      max: 100,
      fields: [
        {
          key: "name",
          label: this.$t("create_campaign.traits"),
          thClass: "attribute__name",
        },
        {
          key: "value",
          label: this.$t("dashboard.progress"),
          thClass: "",
        },
      ],
    };
  },
  computed: {
    //Two sided progress bar calcaulation
    /*performance_attrs() {
      if (!this.report || !this.report.high_performance) return [];

      const values = this.report.high_performance.map((item) => {
        const progressBarWidth = calculateTwoSideProgressBarValue(
          item.value,
          TALENT_TWO_SIDE_SLIDER_BREAK_POINT
        );

        return {
          key: item.key,
          value: progressBarWidth,
        };
      });

      return orderBy(values, ["value"], ["desc"]);
    }*/

    //One sided progress bar calcaulation
    performance_attrs() {
      if (!this.report || !this.report.high_performance) return [];

      let values = this.report.high_performance.map((item) => {
        return {
          key: item.key,
          value: item.value,
        };
      });
      values = values.filter(item => item.value > 55)

      return orderBy(values, ["value"], ["desc"]);
    }
  },
};
</script>
